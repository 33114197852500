* { 
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.App {
  text-align: center;  
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;  
  overflow-y: auto;
}

.App main {
  flex: 0 0 100%;
  display: flex;
  flex-flow: column nowrap;  
}


@media only screen and (max-width: 900px) {
  .App {
    float: left;
    text-align: center;  
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
  }
  
  .App main {
    float: left;
    width: 100%;
    display: block;
  }
}