.home {
  flex: 0 0 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;  
  text-align: left;    
}

.home-logo {
  flex: 0 0 50%;
  background: url("../images/logo.png") center no-repeat #FFF;
  background-size:75%;  
  background-position: 50% 50%;
}

.home-intro {  
  flex: 0 0 50%;    
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;  
  background-color: #ff6c00;    
}

.home-intro-header {
  width: 100%;
}

.home-intro-header img {
  width: 100%;
}

.home-intro-content {
  width: 100%;
  padding: 40px 100px;
  text-align: right;
  color: #FFF;  
}

.home-intro-content h1 {
  font-size: 2em;
  margin: 0 0 30px 0;  
}

.home-intro-content p {
  font-size: 1.1em;
  margin: 10px 0;  
  line-height: 1.5;  
}

.home-intro-content p strong {
  display: inline-block;
  padding: 10px 0;  
}

.home-intro .button {
  display: flex;
  float: right;
  margin-top: 25px;
  max-width: 180px;
  height: 40px;    
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  text-align: center;
  font-size: 0.8em;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  line-height: 1;
  font-weight:bold;
  text-decoration: none;
}

.home-intro .button:hover {
  background-color: #FFFFFF;
  color: #ff6c00;
}

.home-intro em {
  font-style: normal;
  font-weight: bold;
  font-size: 1.1em;
}

.t-orange {
  color: #ff6c00;
}
.t-black {
  color: #000;
}
.t-white {
  color: #FFF;
}

@media only screen and (max-width: 900px) {
  
  .home {
    display: block;
    float: left;
    width: 100%;
    position: relative;        
    text-align: left;            
  }
  
  .home-logo {
    display: block;
    float: left;
    height: 150px;
    width: 100%;    
  }
  
  .home-intro {    
    display: block;
    float: left;
    width: 100%;    
  }

  .home-intro-header {    
  }

  .home-intro-content {
    padding: 30px;
    text-align: center;
  }

  .home-intro-content h1 {
    font-size: 1.5em;    
    margin: 0 0 30px 0;  
  }
  
  .home-intro-content p {
    font-size: 1em;  
  }

  .home-intro-content .button {
    float: none;
    margin: 25px auto;
  }
}


@media only screen and (max-height: 768px) {
  .home-logo {
    background-position: 50% 40%;
  }

  .home-intro-content h1 {
    font-size: 1.4em;      
  }
  
  .home-intro-content p {
    font-size: 0.9em;  
  }
}



