.detail {
  font-style: normal;
  font-size: 12px;
}

.requests {
  text-align: left;  
}

.requests-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.requests-logo {
  flex: 0 0 50%;
  background: url("../../images/logo_pictogram.png") center no-repeat #FFF;
  background-position: left;
  background-size: contain;    
}


@keyframes blinker {
  from { opacity: 1; }
  to { opacity: 0; }  
}

.blink {
  animation: blinker 1s linear infinite;
}

.request-row-cards{
  display: flex;
  flex-flow: row wrap;
  column-gap: 25px;
  row-gap: 25px;
  padding: 25px 50px
}